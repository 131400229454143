body, html, #root
{
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: #1380bc;
}
a, a:hover
{
  color: #222222;
}
a.header-button, a.header-button:hover
{
  color: #ffffff;
  margin-left: 6px;
  margin-right: 12px;
}
h1
{
  font-size: 28px;
}
h2
{
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  margin: 6px 0px 10px 0px;
  color: #0027bc;
  font-weight: bolder;
}
.header a, .header a:hover
{
  color: #fff;
}
.app
{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.global-loading
{
  position: fixed !important;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
}
.loading
{
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(13, 15, 48, 0.66);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.icon-phone
{
  font-size: 25px;
  padding-top: 10px;
  padding-right: 10px;
}
.header
{
  position: fixed;
  z-index: 900;
  top: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:  #4aa3d3;
  color: #fff;
  justify-content: flex-end;
  padding-right: 50px;
  padding-bottom: 5px;
  flex-wrap: wrap;
}
.header2
{
  padding-top: 55px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
}
.main
{
  display: flex;
  color: #fff;
  background-color: #1380bc;
  flex: 1;
  flex-direction: column;
  padding: 8px;
}
.authorized-routes
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.navigation-container
{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 8px 8px 0px 8px;
  margin-right: 10px;
  border: 1px solid #065987;
  border-radius: 5px;
  width: 200px;
  position: relative;
}
.nav-item
{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #666666;
  margin-bottom: 8px;
  padding: 8px;
  background-color: #065987;
  border-radius: 4px;
  font-weight: 600;
  transition: 0.4s;
}
.nav-item i
{
  margin-right: 6px
}
.nav-item:hover, .nav-item-active
{
  color: #222222;
  background-color: #4aa3d3;
}
.display-area
{
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  color: #222222;
  background-color: #ffffff;
  border: 1px solid #065987;
  border-radius: 5px;
}
.display-area-main
{
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 22px;
}
.display-area-header
{
  background-color: #065987;
  color: #ffffff;
  border-bottom: 1px solid #222222;
  padding: 6px 12px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.display-area-body
{
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  padding: 8px;
  overflow: auto;
}
.pools-table-container
{
  position: relative;
  display: block;
  width: 100%;
  overflow: auto;
}
.pools-table
{
  width: 100%;
}
.pools-table td, 
.pools-table th
{
  border: 1px solid #999999;
  border-collapse: collapse;
  padding: 4px;
}
.pools-table tr:nth-child(odd)
{
  background-color: #cfe6ff;
}
.pools-table th
{
  background-color: #065987;
  color: #ffffff;
}
.pools-table th a
{
  color: #ffffff;
  text-decoration: none;
}
.pools-table-horizontal th
{
  text-align: right;
  padding-right: 20px;
  width: 200px;
}
.login-container
{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.login-dialog
{
  width: 450px;
  color: #333333;
  background-color: #fff;
  justify-self: center;
  align-self: center;
  border-radius: 4px;
  border: 1px solid #999999;
  box-shadow: #222222 5px 5px 10px;
}
.login-header
{
  background-color: #cccccc;
  font-weight: 600;
  padding: 5px 10px;
}
.login-body
{
  padding: 14px;
}
.form
{
  display: flex;
  flex-direction: column;
}
.form *
{
  margin-bottom: 6px;
}
.form-submit
{
  display: none
}
.text-field
{
  border: 1px solid #333333;
  padding: 8px;
  border-radius: 4px;
}
.text-field:active, 
.text-field:hover
{
  background-color: #d0eeff;
}
.large-text-field
{
  width: 100%;
}
.select
{
  padding: 8px;
  border-radius: 8px;
  background-color: #1380bc;
  color: #fff;
  box-shadow: #065987 1px 1px 1px;
  transition: 0.4s
}
.select:hover
{
  background-color: #4aa3d3;
}
.email-field
{
  width: 280px;
}
.phone-select
{
  margin-left: 20px;
}
.phone-input-container
{
  display: flex;
  flex-direction: row;
}
.PhoneInput
{
  width: 280px;
}
.PhoneInputInput
{
  padding: 8px;
}
.button-container
{
  display: flex;
  justify-content: space-around;
  margin-top: 18px;
  margin-bottom: 18px;
}
.acct-button-container {
    margin-bottom: 18px;
}
a.logout-button
{
  width: 100px;
  background-color: #065987;
  margin-left: 24px;
  height: 40px;
}
a.logout-button:hover
{
  width: 100px;
  background-color: #1380bc;
}
.button, 
.button:hover
{
  color: #fff;
  border: 1px solid #065987;
  border-radius: 6px;
  font-weight: bold;
  background-color: #4aa3d3;
  text-decoration: none;
  text-align: center;
  padding: 8px;
  width: 200px;
  transition: 0.5s;
  box-shadow: #065987 1px 1px 1px;
}
.button:hover, 
.button:active
{
  background-color: #56bbf1;
  border: 1px solid #4aa3d3;
  
}
.checkbox
{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.checkbox i
{
  color: #065987;
  margin-left: 6px;
}
.footer
{
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  background-color: #065987;
  color: #fff;
  justify-self: flex-end;
  font-size: 12px;
  padding: 4px 10px;
}
.phone-num
{
  text-decoration: none;
  display: flex;
  margin-right: 16px;
  align-items: center;
}
.error
{
  font-weight: 700;
  color: #c10000;
}
.success
{
  font-weight: 700;
  color: #009000;
}
.search-area
{
  display: flex;
  flex-direction: row;
  width: 400px;
}
.search-area .text-field
{
  margin-right: 12px;
}
.search-area .button
{
  width: 100px;
}
.pager
{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px; 
}
.pager-button
{
  margin-left: 8px; margin-right: 8px;
}
.pager-button-active, .pager-button-active:hover, .pager-button-active:active
{
  color: #222222;
  background-color: #cccccc;
}
.widget-container
{
  padding-top: 12px;
  padding-bottom: 8px;
  position: relative;
}
@media screen and (max-width: 500px) {
  .header
  {
    justify-content: center;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 55px;
  }
  .header2
  {
    padding-top: 105px;
  }
  .main
  {
    padding-left: 0px;
    padding-right: 0px;
  }
  .login-container
  {
    align-items: flex-start;
    margin-top: 190px;
  }
  .login-dialog
  {
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    align-self: auto;
  }
  .text-field
  {
    padding: 10px;
  }
  a.logout-button
  {
    margin-top: 6px;
    margin-bottom: 4px;
    width: 220px;
  }
  a.logout-button:hover
  {
    width: 220px;
  }
  .button
  {
    padding: 10px;
  }
  .phone-input-container, .center-input
  {
    flex-direction: column;
    align-items: center;
  }
  .center-input
  {
    display: flex;
    width: 100%;
  }
  .PhoneInput
  {
    width: 220px;
  }
  .select
  {
    width: 220px;
  }
  .email-field
  {
    width: 220px;
  }
  .button
  {
    margin-left: 5px;
    margin-right: 5px;
  }
  .button-container
  {
    flex-direction: column;
    align-items: center;
  }
  .button-container .button
  {
    margin-bottom: 12px;
  }
  .dropdown-area .cust-select,
  .dropdown-area .flag-select
  {
    margin-bottom: 12px;
  }
  .search-area
  {
    align-items: center;
    flex-direction: column;
  }
  .search-area .text-field
  {
    margin-right: 0px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 600px) {
  .authorized-routes
  {
    flex-direction: column;
  }
  .header2
  {
    position: fixed;
    background-color: #ffffff;
    z-index: 800;
    left: 0px; right: 0px;
  }
  .navigation-container
  {
    position: fixed;
    z-index: 800;
    top: 178px;
    left: 0px;
    right: 0px;
    width: 100%;
    border-radius: 0px;
    flex-direction: row;
    justify-content: center;
    height: 68px;
    border-left: 0px;
    border-right: 0px;
  }
  .nav-item
  {
    font-size: 20px;
    margin-right: 8px;
    width: 50px;
    height: 50px;
    justify-content: center;
  }
  .nav-item i
  {
    margin-right: 0px;
  }
  .nav-text
  {
    display: none;
  }
  .display-area
  {
    border-radius: 0px;
    margin-top: 235px;
  }
}